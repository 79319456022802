<template>
  <div class="top-box">
    <div class="top-title">
      <div class="title-cn">发展历程</div>
    </div>
  </div>
  <ul class="about-history-list">
    <!-- <li class="list-item item1">推出智慧管廊产品v1.0</li>
    <li class="list-item item2">推出智慧管线产品v1.0 智慧供热产品v1.0</li>
    <li class="list-item item3">智慧管廊落地苏州 推出智慧轨道产品v1.0</li>
    <li class="list-item item4">智慧管廊产品升级至v2.0 </li>
    <li class="list-item item5">智慧管廊落地合肥 推出智慧水务产品v1.0</li>
    <li class="list-item item6">智慧管廊产品升级至v3.0 推出IGC-D/A/T/E平台v1.0</li>
    <li class="list-item item7">智慧管廊落地石家庄、南京</li>
    <li class="list-item item8">智慧管廊落地北京、天津、雄安新区</li> -->
    
    <li class="list-item item1">
      <p class="year">2015</p>
      <p class="text">推出智慧管廊产品v1.0</p>
    </li>
    <li class="list-item item2">
      <p class="year">2016</p>
      <p class="text">推出智慧管线产品v1.0 智慧供热产品v1.0</p>
    </li>
    <li class="list-item item3">
      <p class="year">2017</p>
      <p class="text">智慧管廊落地苏州 推出智慧轨道产品v1.0</p>
    </li>
    <li class="list-item item4">
      <p class="year">2018</p>
      <p class="text">智慧管廊产品升级至v2.0</p>
    </li>
    <li class="list-item item5">
      <p class="year">2019</p>
      <p class="text">智慧管廊落地合肥 推出智慧水务产品v1.0</p>
    </li>
    <li class="list-item item6">
      <p class="year">2020</p>
      <p class="text">智慧管廊产品升级至v3.0 推出IGC-D/A/T/E平台v1.0</p>
    </li>
    <li class="list-item item7">
      <p class="year">2021</p>
      <p class="text">智慧管廊落地石家庄、南京</p>
    </li>
    <li class="list-item item8">
      <p class="year">2022</p>
      <p class="text">智慧管廊落地北京、天津、雄安新区</p>
    </li>
    <li class="list-item item9">
      <p class="year">2023</p>
      <p class="text">cim平台落地 北京通州文旅区</p>
    </li>
  </ul>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.top-box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 35px 0;
  box-sizing: border-box;

  .top-title {
    color: #fff;
    display: flex;
    align-items: flex-end;
    .title-cn {
      font-size: 24px;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      border-bottom: 2px solid #2b62ff;
      font-weight: 700;
    }
  }
}
.about-history-list{
  width: 100%;
  height: 250px;
  position: relative;

  &::before{
    content: '';
    display: inline-block;
    width: 620px;
    height: 200px;
    background-image: url('../../assets/img/aboutus/line.png');
    position: absolute;
    background-size: 100% 100%;
    left: 55px;
    top: 20px;
  }

  .list-item{
    position: absolute;
    color: #f9faffcf;
    font-size: 14px;
    &::before{
      content: '';
      display: inline-block;
      width: 15px;
      height: 25px;
      background-image: url('../../assets/img/aboutus/qizi.png');
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      left: -25px;
    }
    .year{
      color: #fff;
      line-height: 20px;
    }
    .text{
      color: #fff;
    }
  }
  .item1{
    bottom: 23px;
    left: 72px;
  }
  .item2{
    bottom: 37px;
    left: 208px;
  }
  .item3{
    bottom: 57px;
    left: 300px;
  }
  .item4{
    bottom: 80px;
    left: 401px;
  }
  .item5{
    bottom: 103px;
    left: 476px;
  }
  .item6{
    bottom: 129px;
    left: 536px;
  }
  .item7{
    bottom: 156px;
    left: 582px;
  }
  .item8{
    bottom: 188px;
    left: 635px;
  }
  .item9{
    bottom: 217px;
    left: 697px;
  }
}

@media only screen and (max-width: 500px) {
  .about-history-list{
    display: none;
  }
}
</style>
