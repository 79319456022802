<template>
  <div class="top-box">
    <div class="top-title">
      <div class="title-cn">基本信息</div>
    </div>
  </div>
  <div class="about-r-text">
    苏州智图云科技有限公司是智慧城市基础设施运营管理专家，
    是国内智慧地下综合管廊平台建设与智能化解决方案的领跑
    者。公司始终以让城市运行更安全、更智能、更高效为使命，
    凭借深厚技术积淀，为管网、园区、水务、交通等城市建设、
    管理及运行相关行业提供信息化项目咨询、技术研究、系统集
    成和应用开发，实现数字孪生、物联网、云平台等核心技术的
    跨行业赋能，成为城市建设与管理者最可信赖的合作伙伴。
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
.top-box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 35px 0;
  box-sizing: border-box;

  .top-title {
    color: #fff;
    display: flex;
    align-items: flex-end;
    .title-cn {
      font-size: 24px;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      border-bottom: 2px solid #2b62ff;
      font-weight: 700;
    }
    .title-en {
      font-size: 12px;
      border-bottom: 2px solid #d4d6df;
      line-height: 40px;
    }
  }
  .top-more {
    font-size: 13px;
    color: silver;
  }
}
.about-tx{
  width: 450px;
  padding: 15px 23px;
  background: #4575ff;
  position: absolute;
  box-sizing: border-box;
  left: 23px;
  top: 60px;
  z-index: 2;
  .about-tx-ti{
    font-weight: 700;
    color: #fff;
    font-size: 16px;
  }
  .about-tx-ci{
    font-size: 12px;
    color: #fff;
    margin: 10px 0;
  }
  .about-tg{
    width: 23px;
    height: 3px;
    background: #fff;
  }
  .about-row{
    display: flex;
    font-size: 12px;
    color: #fff;
    margin-top: 15px;
    .about-row-le{
      width: 35%;
    }
    .about-row-ri{
      flex: 1;
    }
  }
}
.about-timg{
  width: 654px;
  height: 368px;
  position: relative;
  left: 117px;
  top: 40px;
  z-index: 1;
}
.about-r-text{
  width: 360px;
  padding: 20px 15px;
  background: #d8e5ff;
  font-size: 12px;
  color: #535b6c;
  line-height: 24px;
  position: absolute;
  right: 70px;
  bottom: 80px;
  z-index: 2;
  box-sizing: border-box;
}

@media only screen and (max-width: 500px) {
  .about-r-text{
    right: 4vw;
    width: 100%;
    bottom: 66px;
    left: 0;
  }
}

</style>
